import React, {  useState, useEffect } from 'react';
import IFrame from './IFrame';
import { APIUrl } from './globals';

const App = () => {
    
    const queryParams = new URLSearchParams(window.location.search);
    
    const [embedURL, setEmbedURL] = useState("");
    const [loading, setLoading] = useState(true);
    const [cNum, setCNum] = useState(queryParams.get("c") ?? "none");
    const [saved, setSaved] = useState(false);
    const [showCNumError, setShowCNumError ] = useState(false);

    useEffect(() => {

        const getEmbedURL = async () => {
            const response = await fetch(`${APIUrl}/vopay/GetURL?c=${cNum}`);
            const data = await response.json();
            return data;
            //console.log("data", data);
    
        }

        if(cNum !=="none"){
            setShowCNumError(false);
            getEmbedURL().then(data => {    
                console.log("Data", data);
                if (data) {
                    setEmbedURL(data.EmbedURL ?? data.OnboardingLink)
                    setLoading(false);
                }
            });
        }
        else{
            console.log("error");
            setLoading(false);
            setShowCNumError(true);
        }
    },[cNum]);

    
    const saveClientHandlerListener =  (data) => {
        if (data.Step === "COMPLETE") {
            const clientAccount = async (vopay) => {
                const config = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        CustomerNumber: cNum,
                        VopayClientAccountID: vopay.ClientAccountID
                    })
                }
                const response = await fetch(`${APIUrl}/vopay/SaveClientAccount`, config);
                const data = await response.json();
                return data;

            }
            clientAccount(data).then(resp => {
                setSaved(true);
                console.log("resp", resp);
            })
        }

    };

    const RenderIFRAME = () => {
        
        return (
            loading
                ? <div className="loading">
                    Loading...
                </div>
                :

                <IFrame url={embedURL} handleEvent={saveClientHandlerListener} />
            );
    }

    return (
        <div className="container">
            <div className="logo-gallery">
                <img src="https://images.salsify.com/image/upload/s--br1jjGqd--/tpe6idjhfrjf6wvkddch" alt=""/>
            </div>

            {
                saved
                ?
                <p>
                    Thank you for attaching a bank account to your my.SONANCE profile. You will now be able to make payments via ACH/EFT in the future.
                </p>
                :
                <>
                <p>VoPay is Sonance's trusted provider for securely attaching ACH/EFT accounts to our payment systems. Please fill out the form below to link your bank account within my.SONANCE.</p>
                   
                {
                    showCNumError === true
                    ?
                    <p className="error">Hi, you've reached this page with an invalid link. Please contact the individual at Sonance who provided this link to continue.</p>
                    :
                    <RenderIFRAME />
                }
                </>
            }


                
             
        
        </div>
    );
}

export default App;

  
 
    

    

