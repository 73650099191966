import React, { useEffect } from 'react';

const IFrame = (props) => {

    console.log(props);
    const handler = event => {
        const data = event.data;

        //const data =  { "Success": true, "Message": "Client account has been successfully created.", "ClientAccountID": "Sonance_bus_cndspeci" }        ;
        props.handleEvent(data);
        console.log("iframe",data);
    }

    useEffect(() => {

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
    })


    return (
        <>
           
            <iframe src={props.url} title="Vopay Iframe"></iframe>

        </>
        
        )
}

export default IFrame;